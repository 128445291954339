<template>
  <div class="app__footer">
    <div class="app__footer--header">
      <div class="app__footer--header__hr"><hr /></div>

      <div class="app__footer--header__content">
        <div class="app__footer--header__content--container">
          <p class="app__footer--header__content--container__text">
            Ready to <span>light</span> up your life
          </p>
          <button class="app__footer--header__content--container__btn" @click="goToApp">
            <p>Get started now</p>
          </button>
        </div>
      </div>
    </div>

    <div class="app__footer--contacts">
      <div class="app__footer--contacts__box">
        <div class="app__footer--contacts__box--legal contacts__text">
          <h5>Legal</h5>

          <router-link
            :to="{
               name: 'TermsConditionView',
               params: { tab: 'tab1' },
            }"
          >
            <p>Terms of Use</p>
          </router-link>

          <router-link
            :to="{
               name: 'TermsConditionView',
               params: { tab: 'tab2' },
            }"
          >
            <p>Private Policy</p>
          </router-link>
          <!-- <p>Loan Offers</p> -->
        </div>

        <div class="app__footer--contacts__box--contact contacts__text">
          <h5>Contact</h5>
          <p>+2348103535783</p>
          <p>support@powernow.io</p>
        </div>

        <div class="app__footer--contacts__box--location contacts__text">
          <h5>Location</h5>
          <p>51C Remi Fani-Kayode Street, GRA Ikeja, Lagos</p>
        </div>
      </div>

      <div class="app__footer--contacts__social">
        <router-link to="/" class="large">
          <div class="app__footer--contacts__social--logo">
            <img src="@/assets/black_logo.svg" alt="logo" />
          </div>
        </router-link>

        <div class="app__footer--contacts__social--icons">
          <a
            href="https://www.facebook.com/profile.php?id=100095359369356&mibextid=D4KYlr"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="@/assets/facebook.svg" alt="facebook" />
          </a>

          <a href="https://x.com/powernow_io" target="_blank" rel="noreferrer noopener">
            <img src="@/assets/x.svg" alt="twitter" />
          </a>

          <a href="https://instagram.com/powernow.io?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noreferrer noopener">
            <img src="@/assets/instagram.svg" alt="instagram" />
          </a>

          <a
            href="https://www.linkedin.com/in/powernow-paylater-18127a287"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="@/assets/linkedin.svg" alt="linkedin" />
          </a>

          <a href="https://youtube.com/@powernownigeria?si=6HeGzQpSrMUU5EPl" target="_blank" rel="noreferrer noopener">
            <img src="@/assets/youtube.svg" alt="youtube" />
          </a>

          <a href="https://api.whatsapp.com/send?phone=2348103535783" target="_blank" rel="noreferrer noopener">
            <img src="@/assets/whatsapp.svg" alt="whatsapp" />
          </a>
        </div>

        <router-link to="/" class="mobile">
          <div class="app__footer--contacts__social--logo">
            <img src="@/assets/mobile_black_logo.svg" alt="logo" />
          </div>
        </router-link>

        <p>Copyright © 2024 | DT2 Technologies</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const url = computed(() => process.env.VUE_APP_GREEN_ENERGY_WEB_APP_URL);

const goToApp = () => {
  const anchor = document.createElement('a');
  anchor.href = url.value;
  anchor.target = "_blank";
  anchor.click();
}
</script>